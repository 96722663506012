import React from 'react'
import { CloseRounded } from '@mui/icons-material'
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
} from '@mui/material'

const Modal = ({
  title,
  iconStyle,
  openModal,
  setOpenModal,
  setItemToAdd,
  action,
  customClass,
  children,
  showCloseIcon = true,
  onClose = () => {},
}) => {
  const handleClose = (e) => {
    setOpenModal(false)
    if (setItemToAdd) setItemToAdd(null)
    onClose(e)
  }

  const dialogPaperStyle = {
    width: '100%',
    maxWidth: '840px',
    margin: 'auto',
  }

  return (
    <Dialog
      onClose={handleClose}
      open={openModal}
      className={`${customClass}`}
      PaperProps={
        customClass === 'zoom-img-container'
          ? { style: dialogPaperStyle }
          : { style: {} }
      }
    >
      {title && (
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          fontWeight={400}
          style={{ textAlign: 'center' }}
        >
          <b>{title}</b>
        </DialogTitle>
      )}
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={
            iconStyle || {
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }
          }
        >
          <CloseRounded />
        </IconButton>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {!!action && <DialogActions>{action}</DialogActions>}
    </Dialog>
  )
}

export default Modal
