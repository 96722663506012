import { createContext, useContext, useState } from 'react'
const AuthContext = createContext()

function AuthProvider({ children }) {
  const [login, setLogin] = useState(true)
  const [profile, setProfile] = useState()
  const [permissions, setPermissions] = useState([])
  const [userId, setUserId] = useState()

  return (
    <AuthContext.Provider
      value={{
        login,
        setLogin,
        profile,
        setProfile,
        permissions,
        setPermissions,
        userId,
        setUserId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export default AuthProvider
