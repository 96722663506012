import React, { useEffect } from 'react'
import { ReactComponent as RightArrow } from '../../../assets/icons/RightArrow.svg'
import styles from './SelectQCStatus.module.scss'

const SelectStatus = ({
  statusId,
  setStatusId,
  openDropdown,
  setOpenDropdown,
  setHasmore,
  setSkip,
  setOrders,
}) => {
  const toggleAssign = (id) => {
    if (statusId === id) return
    setHasmore(true)
    setSkip(0)
    setOrders([])
    setStatusId(id)
    setOpenDropdown(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        openDropdown &&
        !event.target.closest('#quantity-select') &&
        !event.target.closest('#open-dropdown')
      ) {
        setOpenDropdown(false)
      }
    }
    const handleKeyDown = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        setOpenDropdown(false)
      }
    }
    document.body.addEventListener('click', handleClickOutside)
    document.body.addEventListener('keydown', handleKeyDown)
    return () => {
      document.body.removeEventListener('click', handleClickOutside)
      document.body.removeEventListener('keydown', handleKeyDown)
    }
  }, [openDropdown])

  const data = [
    {
      id: 60,
      name: 'QC Approval Pending',
    },
    {
      id: 61,
      name: 'QC Approved',
    },
  ]

  return (
    <div className={styles.select_status}>
      <div
        onClick={(e) => e.preventDefault()}
        className={styles.change_cart_btn}
      >
        <div
          onClick={() => {
            setOpenDropdown((prev) => !prev)
          }}
          id="open-dropdown"
          className={styles.open_dropdown}
        >
          Status
          <RightArrow className={styles.arrow} />
        </div>
        {openDropdown && (
          <div className={styles.num_dropdown}>
            {data.map((item) => (
              <div
                key={item.id}
                onClick={() => toggleAssign(item.id)}
                id="quantity-select"
                className={`${styles.quantity_select} ${
                  statusId === item.id ? styles.quantity_select_active : ''
                }`}
              >
                {item.name} <span>id:{item.id}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectStatus
