import { useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/authContext'
import { useMediaQuery, useTheme } from '@mui/material'

const LeadRow = ({ loading, hasmore, fetchData, index, item, orders }) => {
  const { permissions } = useAuth()
  const navigate = useNavigate()
  const theame = useTheme()
  const isMobile = useMediaQuery(theame.breakpoints.down('sm'))

  const getTimestamp = (time) => {
    const dateObj = new Date(time)
    const istTime = dateObj.toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata',
    })
    return istTime
  }

  const observer = useRef()

  const lastElementRef = useCallback(
    (node) => {
      if (loading || !hasmore) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchData(permissions)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasmore],
  )

  const handleNavigate = (orderId, statusId) => {
    navigate(`/qc-order/${orderId}/${statusId}`)
  }

  if (orders.length - 1 === index) {
    return (
      <tr
        ref={lastElementRef}
        key={item.orderId}
        className={index % 2 === 0 ? 'even' : 'odd'}
      >
        {!isMobile && (
          <td
            onClick={() => handleNavigate(item.orderId, item.statusId)}
            className={`${index === orders.length - 1 ? 'border-4' : ''}`}
          >
            {index + 1}
          </td>
        )}

        <td
          className="order-id"
          onClick={() => handleNavigate(item.orderId, item.statusId)}
        >
          {item.orderId}
        </td>
        <td onClick={() => handleNavigate(item.orderId, item.statusId)}>
          {getTimestamp(item.createdAt)}
        </td>
        <td onClick={() => handleNavigate(item.orderId, item.statusId)}>
        {item.userId}
        </td>
        {!isMobile && (
          <td onClick={() => handleNavigate(item.orderId, item.statusId)}>
            {item.orderFulfillmentDate}
          </td>
        )}
      </tr>
    )
  } else {
    return (
      <tr key={item.orderId} className={index % 2 === 0 ? 'even' : 'odd'}>
        {!isMobile && (
          <td
            onClick={() => handleNavigate(item.orderId, item.statusId)}
            className={`${index === orders.length - 1 ? 'border-4' : ''}`}
          >
            {index + 1}
          </td>
        )}
        <td
          onClick={() => handleNavigate(item.orderId, item.statusId)}
          className={`order-id ${
            index === orders.length - 1 ? 'border-4' : ''
          }`}
        >
          {item.orderId}
        </td>
        <td onClick={() => handleNavigate(item.orderId, item.statusId)}>
          {getTimestamp(item.createdAt)}
        </td>
        <td onClick={() => handleNavigate(item.orderId, item.statusId)}>
        {item.userId}
        </td>
        {!isMobile && (
          <td onClick={() => handleNavigate(item.orderId, item.statusId)}>
            {item.orderFulfillmentDate}
          </td>
        )}
      </tr>
    )
  }
}

export default LeadRow
