import Header from '../../components/Header/Header'
import './Layout.css'
import { ThemeProvider } from '@mui/material'
import theme from '../../theme'
const Layout = ({ title, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="layout">
        <Header title={title} />
        {children}
      </div>
    </ThemeProvider>
  )
}

export default Layout
