import { useNavigate } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

import { useAuth } from '../../context/authContext'
import './Header.css'
function Header({ title }) {
  const { setLogin, setProfile, setPermissions } = useAuth()
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('platinumRxDashboardUserDetail')
    localStorage.removeItem('platinumRxDashboardToken')
    localStorage.removeItem('user_id')
    setLogin(false)
    setProfile(null)
    setPermissions([])
  }

  return (
    <nav className="header-nav">
      <div
        style={{
          fontSize: '20px',
          fontWeight: '700',
          color: '#000000',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <KeyboardBackspaceIcon
          onClick={() => navigate(-1)}
          style={{
            cursor: 'pointer',
            borderRadius: '999px',
            border: '3px solid black',
          }}
        />
        &nbsp; &nbsp; {title}
      </div>
      <div style={{ display: 'flex', gap: '25px', alignItems: 'center' }}>
        <span
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#000000',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/profile')}
        >
          Profile
        </span>
        <span
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#000000',
            cursor: 'pointer',
          }}
          onClick={logout}
        >
          Logout
        </span>
      </div>
    </nav>
  )
}

export default Header
