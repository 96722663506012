import React, { useEffect, useState } from 'react'
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid'
import styles from './purchase.module.scss'
import {
  getPurchaseOrderItems,
  updatePurchaseOrderStatus,
} from '../../utils/api'
import { Autocomplete, TextField } from '@mui/material'
import { vendorOptions } from '../../utils/constants'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import UpdateStatusModal from './UpdateStatusModal'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const PurchaseOrders = () => {
  const [loading, setLoading] = useState(false)
  const [purchaseItems, setPurchaseItems] = useState([])
  const [masterDrugCodes, setMasterDrugCodes] = useState('')
  const [selectedZohoIds, setSelectedZohoIds] = useState([])
  const [rowModesModel, setRowModesModel] = useState({})
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  const fetchPurchaseOrderItems = async () => {
    try {
      setLoading(true)
      let data = {
        data: {
          masterDrugCodes: masterDrugCodes
            .split(',')
            .map((drugCode) => parseInt(drugCode))
            .filter(Boolean),
          vendorZohoIds: selectedZohoIds.map((option) => option.id),
        },
        params: {
          skip: 0,
          limit: 1500,
        },
      }
      const response = await getPurchaseOrderItems()(data)
      if (response) {
        setPurchaseItems(response.data.purchaseOrderItems)
      }
      setLoading(false)
    } catch (e) {
      console.log('error', e)
      setLoading(false)
    }
  }

  const handleStatusUpdate = (id, data) => {
    try {
      if (data?.quantity === data?.receivedQuantity) return
      setSelectedItem(data)
      setOpen(true)
    } catch (error) {
      console.error('Error updating status:', error)
    }
  }

  const handleEditClick = (id, row) => {
    // Only enable editing if the order is not fulfilled
    if (row.quantity !== row.receivedQuantity) {
      setRowModesModel((prev) => ({
        ...prev,
        [id]: { mode: 'edit' },
      }))
    }
  }

  const handleSaveClick = (id) => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: 'view' },
    }))
  }

  useEffect(() => {
    fetchPurchaseOrderItems()
  }, [masterDrugCodes, selectedZohoIds])

  const UpdatePO = async (updatedItem, oldItem, markAsClosed) => {
    try {
      setLoading(true)

      const data = {
        id: updatedItem.id,
        data: {
          receivedQuantity: Number(updatedItem.receivedQuantity),
          purchaseRate: parseFloat(updatedItem.purchaseRate),
          eta: updatedItem.eta,
          markAsClosed: markAsClosed,
        },
      }

      const response = await updatePurchaseOrderStatus()(data)

      if (response.data.success === 1) {
        fetchPurchaseOrderItems()
        setRowModesModel((prev) => ({
          ...prev,
          [updatedItem.id]: { mode: 'view' },
        }))
      }
    } catch (error) {
      console.log('Error saving item:', error)
      return oldItem
    } finally {
      setLoading(false)
    }
    return updatedItem
  }

  const processRowUpdate = (updatedItem, oldItem) => {
    return UpdatePO(updatedItem, oldItem)
  }

  const handleProcessRowEditStart = (params, event) => {
    const { quantity, receivedQuantity } = params.row
    if (quantity === receivedQuantity) {
      event.defaultMuiPrevented = true // Prevent row from entering edit mode
    }
  }

  const columns = [
    { field: 'id', headerName: 'P.O. ID', width: 70, editable: false },

    {
      field: 'masterDrugCode',
      headerName: 'Drug Code',
      type: 'number',
      width: 100,
      valueFormatter: (row, value) => {
        return row ? row.toString() : ''
      },
      editable: false, // Not editable
    },
    {
      field: 'displayName',
      headerName: 'Drug Name',
      width: 150,
      editable: false,
    },
    {
      field: 'quantity',
      headerName: 'Order Quantity',
      type: 'number',
      width: 120,
      editable: false,
    },
    {
      field: 'receivedQuantity',
      headerName: 'Received Quantity',
      type: 'number',
      width: 150,
      editable: true, // Not editable
    },
    {
      field: 'pendingQuantity',
      headerName: 'Pending Quantity',
      type: 'number',
      width: 150,
      editable: false, // Not editable
    },
    {
      field: 'purchaseRate',
      headerName: 'Purchase Rate',
      type: 'number',
      width: 120,
      editable: true,
      valueFormatter: (row, value) => `₹${parseFloat(row).toFixed(2)}`, // Format as currency
    },

    {
      field:'orderDate',
      headerName: 'Order Date',
      width: 100,
      editable: false,
    },
    {
      field: 'vendorZohoId',
      headerName: 'Vendor',
      width: 200,
      renderCell: (params) => {
        const vendor = vendorOptions.find((v) => v.id === params.value)
        return vendor ? vendor.name : 'Unknown'
      },
    },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 100,
      editable: true,
      renderEditCell: (params) => (
        <DatePicker
          value={dayjs(params.value)} // Use dayjs for easier date handling
          onChange={(newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: 'eta',
              value: newValue?.format('YYYY-MM-DD'),
            })
          }}
          renderInput={(inputProps) => <TextField {...inputProps} />}
          minDate={dayjs()} // Restrict date selection to today or future
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      editable: false, // Not editable
    },
    {
      field: 'Update status',
      headerName: 'Mark as Closed',
      width: 150,
      renderCell: (row, value) => (
        <div onClick={() => handleStatusUpdate(row.id, row.row)}>
          {row.row.quantity !== row.row.receivedQuantity ? (
            <button
              className={`${styles.fullFilled} ${styles.fullFilled_not_done}`}
            >
              Mark as Closed
            </button>
          ) : (
            <button
              disabled={true}
              className={`${styles.fullFilled} ${styles.fullFilled_done}`}
            >
              Closed
            </button>
          )}
        </div>
      ),
      editable: false, // Not editable
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      type: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === 'edit'
        const isFulfilled = params.row.quantity === params.row.receivedQuantity

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={() => handleSaveClick(params.id)}
              color="primary"
            />,
          ]
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => handleEditClick(params.id, params.row)}
              color="inherit"
              disabled={isFulfilled} // Disable editing if the order is fulfilled
            />,
          ]
        }
      },
    },
  ]

  const rows = purchaseItems?.map((item) => ({
    id: item.id,
    vendorZohoId: item?.purchaseOrder?.vendorZohoId,
    masterDrugCode: item.masterDrugCode,
    displayName: item.masterDrugData.displayName,
    quantity: item.quantity,
    receivedQuantity: item.receivedQuantity,
    pendingQuantity: item.pendingQuantity,
    purchaseRate: item.purchaseRate,
    eta: item.eta,
    status: item?.purchaseOrder?.status || 'N/A',
    orderDate: dayjs(item.createdAt).format('YYYY-MM-DD'),

  }))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="home">
        <h1>
          <b>Purchase Orders</b>
        </h1>
        <div className="filter-header">
        <TextField
          variant="outlined"
          fullWidth
          label="Enter master drug code (Comma separated if multiple)" 
          onChange={(e) => setMasterDrugCodes(e.target.value)}
        />
          <Autocomplete
            multiple
            options={vendorOptions}
            getOptionLabel={(option) => option.name}
            value={selectedZohoIds}
            onChange={(event, newValue) => setSelectedZohoIds(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Zoho Vendors"
                variant="outlined"
              />
            )}
            style={{ width: '90%', borderRadius: '15px' }}
          />
        </div>{' '}
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          loading={loading}
          autoHeight
          slots={{ toolbar: GridToolbar }}
          editMode="row"
          isCellEditable={(params) =>
            params.row.receivedQuantity !== params.row.quantity
          }
          disableSelectionOnClick={true}
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          processRowEditStart={handleProcessRowEditStart}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
        {selectedItem && open && (
          <UpdateStatusModal
            open={open}
            setOpen={setOpen}
            toggle={() => setOpen(!open)}
            fetchPurchaseOrderItems={fetchPurchaseOrderItems}
            statusData={selectedItem}
          />
        )}
      </div>
    </LocalizationProvider>
  )
}

export default PurchaseOrders