import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import styles from './QCDetails.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { getOrderDetails, MarkProcurementOrderAsQCDone } from '../../utils/api'
import PageLoader from '../../components/Loaders/PageLoader'
import CartDetails from '../../contaniers/QCDetails/CartDetails'
import FileUpload from '../../contaniers/QCDetails/FileUpload'
import BillingDetails from '../../contaniers/OrderPage/BillDetails/BillDetails'

function OrderPage() {
  const { orderId } = useParams()
  const [details, setDetails] = useState()
  const [loading, setLoading] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const navigate = useNavigate()
  const getDetails = async (orderId) => {
    setLoading(true)
    try {
      const response = await getOrderDetails()({ orderId })
      setDetails(response?.data)
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Error fetching order details', { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetails(orderId)
  }, [orderId])

  const handleCheckboxChange = (itemId) => {
    setCheckedItems((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId],
    )
  }

  const handleFilesChange = (files) => {
    setUploadedFiles(files)
  }

  const FormatFiles = (files, fileIds) => {
    const data = new FormData()
    data.append('order_id', fileIds)
    ;[...files].forEach((file) => {
      data.append('files', file.file)
    })
    return data
  }

  const handleSubmit = async () => {
    if (
      checkedItems.length === details?.orderItems?.length &&
      uploadedFiles.length > 0
    ) {
      try {
        setLoading(true)
        const payload = FormatFiles(uploadedFiles, orderId)
        await MarkProcurementOrderAsQCDone()(payload)
        navigate('/qc-listing')
      } catch (error) {
        console.error(error)
        enqueueSnackbar('Error submitting order', { variant: 'error' })
      } finally {
        setLoading(false)
      }
      enqueueSnackbar('Order submitted successfully', { variant: 'success' })
    } else {
      enqueueSnackbar('Please check all items and upload files', {
        variant: 'warning',
      })
    }
  }

  return (
    <>
      <PageLoader open={loading} />
      <div className={styles.order_page}>
        <CartDetails
          orderDetails={details}
          handleCheckboxChange={handleCheckboxChange}
          checkedItems={checkedItems}
        />

        <FileUpload
          filePreviews={uploadedFiles}
          setFilePreviews={setUploadedFiles}
          handleFilesChange={handleFilesChange}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            checkedItems.length !== details?.orderItems?.length ||
            uploadedFiles.length === 0
          }
          style={{ marginTop: '20px', alignSelf: 'flex-start' }}
        >
          Submit Order
        </Button>
        <BillingDetails
          data={details?.orderPriceDetails}
          paymentMode={details?.orderPaymentDetails[0]?.modeOfPayment}
        />
      </div>
    </>
  )
}

export default OrderPage
