import React from 'react'
import styles from '../UploadCSVModal/UploadCSV.module.scss'
import { enqueueSnackbar } from 'notistack'

const SelectReorderFiles = ({ setReorderFiles }) => {
  const ALLOWED_FILE_FORMATS = ['application/csv', 'text/csv']
  const MAX_FILE_SIZE_MB = 15

  const handleFileSelect = (file) => {
    console.log('file', file)
    if (!ALLOWED_FILE_FORMATS.includes(file.type)) {
      return enqueueSnackbar('Invalid Format. only CSV Allowed', {
        variant: 'error',
      })
    }
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      return enqueueSnackbar('FileSize exceeded.', {
        variant: 'error',
      })
    }
    setReorderFiles(file)
  }

  return (
    <div>
      <div className={styles.uploads}>
        <label
          htmlFor="prescriptionUpload"
          className={styles.uploadLabel}
        ></label>
        <input
          type="file"
          id="prescriptionUpload"
          accept="application/csv"
          htmlFor="prescriptionUpload"
          style={{
            display: 'none',
          }}
          onChange={(e) => handleFileSelect(e.target.files[0])}
          multiple
        />
      </div>
    </div>
  )
}

export default SelectReorderFiles
