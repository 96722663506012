import { CircularProgress } from "@mui/material"

import "./Loaders.css"

const ComponentLoader = ({className}) => {
    return(
        <div className={`component-loader ${className}`}><CircularProgress color="secondary" /></div>
    )
}

export default ComponentLoader