import { createContext, useContext, useState } from 'react'
const CartContext = createContext()

function CartProvider({ children }) {
  const [cartData, setCartData] = useState([])
  const [digitizedPrx, setDigitizedPrx] = useState([])
  const [doctorCart, setDoctorCart] = useState([])

  return (
    <CartContext.Provider
      value={{
        cartData,
        setCartData,
        digitizedPrx,
        setDigitizedPrx,
        doctorCart,
        setDoctorCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => {
  return useContext(CartContext)
}

export default CartProvider
