import React, { useEffect, useState } from 'react'
import Modal from '../../components/CustomElements/Modal'
import { getPendingOrderItems } from '../../utils/api'
import styles from './PendingOrder.module.scss'
import { DataGrid } from '@mui/x-data-grid'

const OrderSKUDetailModal = ({ orderId, open, setOpen, toggle }) => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchItems = async () => {
    try {
      setLoading(true)
      const response = await getPendingOrderItems()(orderId)
      console.log("Res", response)
      setItems(response.data.orderItems)

    } catch (e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchItems()
  }, [])

  const columns = [
    {
      field: 'masterDrugCode',
      headerName: 'Master Drug Code',
      width: 140,
      type: 'number',
      valueFormatter: (row, value) => {
        return row ? row.toString() : ''
      },
    },
    {
      field: 'masterDrugName',
      headerName: 'Drug Name',
      width: 250,
      type: 'string',

      },
    {
      field: 'requiredForThisOrder',
      headerName: 'Required Qty',
      type: 'number',
      width: 150,
      valueGetter: (value, row) => row.requiredForThisOrder ?? '-',
    },
    {
      field: 'requiredQuantity',
      headerName: 'Total Required Qty',
      width: 150,
      type: 'number',
    },
    {
      field: 'availableQty',
      headerName: 'Available Qty',
      type: 'number',
      width: 120,
      valueGetter: (value, row) => row.availableQty ?? '-',
    },
    {
      field: 'promisedQty',
      headerName: 'Promised Qty',
      type: 'number',
      width: 120,
      valueGetter: (value, row) => row.promisedQty ?? '-',
    },
  ]

  const rows = items.map((item, index) => ({
    id: index,
    masterDrugCode: item.masterDrugCode,
    requiredQuantity: item.requiredQuantity,
    availableQty: item.availableQty,
    promisedQty: item.promisedQty,
    requiredForThisOrder: item.requiredForThisOrder,
    masterDrugName: item.masterDrugData.displayName,

  }))

  return (
    <Modal
      openModal={open}
      setOpenModal={setOpen}
      showCloseIcon={true}
      onClose={toggle}
      title={`Order ID ${orderId} SKU Details`}
    >
      <div className={styles.modal_padding}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          initialState={{
            sorting: {
              sortModel: [{ field: 'requiredForThisOrder', sort: 'desc' }],
            },
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
          autoHeight
        />
      </div>
    </Modal>
  )
}

export default OrderSKUDetailModal
