import { createContext, useContext, useState } from "react"
const UserContext = createContext()

function UserProvider({children}){
    const [doctorMap, setDoctorMap] = useState({})
    const [pharmacistMap, setPharmacistMap] = useState({})

    return (
        <UserContext.Provider value={{doctorMap, setDoctorMap, pharmacistMap, setPharmacistMap}}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => {
    return useContext(UserContext)
}

export default UserProvider