'use client'
import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FC5A5A',
      white: '#FFFFFF',
    },
  },
  typography: {
    h1: {
      fontSize: 48,
      fontWeight: 700,
      '@media (max-width:768px)': {
        fontSize: 25,
      },
    },
    h2: {
      fontWeight: 500,
      fontSize: 36,
      '@media (max-width:768px)': {
        fontSize: 22,
      },
    },
    h3: {},
    h4: {},
    h5: {},
    h6: {},
    p: {
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 20,
      '@media (max-width:768px)': {
        fontSize: 14,
      },
    },
    body1: {
      '@media (max-width:768px)': {
        fontSize: 12,
      },
    },
    body2: {
      '@media (max-width:768px)': {
        fontSize: 12,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 769,
      md: 1024,
      lg: 1366,
      xl: 1920,
    },
  },
})

export default theme
