import React, { useEffect, useState } from 'react'
import { getDrugReorderPoints } from '../../utils/api'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import {TextField } from '@mui/material'
import UploadCSVModal from '../../contaniers/ReOrderPoints/UploadCSVModal'
import styles from './reOrderPoints.module.scss'

const ReOrderPoints = () => {
  const [ReOrderPoints, setReOrderPoints] = useState([])
  // const [page, setPage] = useState(0)
  // const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [masterDrugCodes, setMasterDrugCodes] = useState('')
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const fetchReOrderPoints = async (page, pageSize) => {
    setLoading(true)
    try {
      const data = {
        params: {
          skip: 0,
          limit: 1500,
        },
        data: {
          masterDrugCodes: masterDrugCodes
            .split(',')
            .map((drugCode) => parseInt(drugCode))
            .filter(Boolean),
        },
      }

      const response = await getDrugReorderPoints()(data)

      if (response) {
        setReOrderPoints(response.data.drugReorderPoints)
      }
    } catch (e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchReOrderPoints()
  }, [masterDrugCodes, open])

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'masterDrugCode',
      headerName: 'master Drug Code',
      width: 150,
      type: 'number',
      valueFormatter: (row, value) => {
        return row ? row.toString() : ''
      },
    },
    { field: 'drugName', headerName: 'Drug Name', width: 350 },
    {
      field: 'reorderPoint',
      headerName: 'Re-order Point',
      width: 150,
      type: 'number',
    },
    {
      field: 'reorderQuantity',
      headerName: 'Re-order Quantity',
      width: 150,
      type: 'number',
    },
  ]

  const rows = ReOrderPoints.map((order, index) => ({
    id: order.id,
    masterDrugCode: order?.masterDrugCode,
    drugName: order?.masterDrugData?.displayName || 'N/A',
    reorderPoint: order?.reorderPoint,
    reorderQuantity: order?.reorderQuantity,
  }))

  return (
    <div className="home">
    <div className="filter-header" >

      <TextField
        fullWidth
        name="masterDrugCode"
        label="Enter master drug code (comma separated if multiple)"
        variant="outlined"
        onChange={(e) => setMasterDrugCodes(e.target.value)}
        />
          <a 
          href="https://docs.google.com/spreadsheets/d/18cnUcK_-j6V8leZAX9Mn4c_sDpeNBF7LWip5Gg2D6Wg/edit?usp=sharing" 
          target="_blank" 
          rel="noopener noreferrer"
          className={styles.sampleFileLink} // Add CSS class for styling if needed
        >
          <u>Sample File</u>
        </a>
        <button onClick={() => setOpen(true)} className={`prx-btn ${styles.uploadFileBtn}`}>
          Upload Re-Order File
        </button>
      </div>

      <div style={{ height: 600 }}> 
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          // paginationMode="server"
          // onPageChange={(newPage) => setPage(newPage)}
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          // page={page}
          loading={loading}
          autoHeight
          disableSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
      </div>
      <UploadCSVModal
        open={open}
        setOpen={setOpen}
        handleOnClose={handleClose}
      />
    </div>
  );
};

export default ReOrderPoints;
