import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { getReorderSuggestions } from '../../utils/api'
import {TextField } from '@mui/material'


const ReorderPointsSummary = () => {
  const [reorderPoints, setReorderPoints] = useState([])
  const [masterDrugCodes, setMasterDrugCodes] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchReorderPoints = async () => {
    setLoading(true)
    try {
      let data = {
        data: {
          masterDrugCodes: masterDrugCodes
            .split(',')
            .map((drugCode) => parseInt(drugCode))
            .filter(Boolean),
        },
        params: {
          skip: 0,
          limit: 1500,
        },
      }

      const response = await getReorderSuggestions()(data)
      if (response) {
        setReorderPoints(response.data.reorderSuggestions)
      }
    } catch (e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchReorderPoints()
  }, [masterDrugCodes])

  const columns = [
    {
      field: 'masterDrugCode',
      headerName: 'Drug Code',
      width: 100,
      type: 'number',
      valueFormatter: (row, value) => {
        return row ? row.toString() : ''
      },
    },
    { field: 'drugName', headerName: 'Drug Name', width: 350,  headerClassName: 'bold-header' },
    { field: 'brand', headerName: 'Brand', width: 150,  headerClassName: 'bold-header' },
    { field: 'drugForm', headerName: 'Drug Form', width: 150,  headerClassName: 'bold-header' },
    {
      field: 'drugStock',
      headerName: 'Drug Stock',
      width: 100,
      type: 'number',
       headerClassName: 'bold-header',
    },
    {
      field: 'packQuantity',
      headerName: 'Pack Qty',
      width: 100,
      type: 'number',
       headerClassName: 'bold-header',
    },
    {
      field: 'stockInWarehouse',
      headerName: 'Qty In WH',
      width: 100,
      type: 'number',
       headerClassName: 'bold-header',
    },
  ]

  const rows = reorderPoints?.map((item, index) => ({
    id: index,
    masterDrugCode: item?.masterDrugCode,
    drugName: item?.masterDrugData?.displayName,
    stockInWarehouse: item.itemProcurement.stockInWarehouse || '-',
    drugStock: item.masterDrugData.drugStock,
    drugForm: item.masterDrugData.drugForm,
    packQuantity: item.masterDrugData.packQuantityValueDecimal,
    brand: item.masterDrugData.manufacturerName.slice(0, 10),
    item: item,
  }))

  return (
    <>
      <div className="home">
        <h1>
          <b>R.P. Summary</b>
        </h1>
        <div className="filter-header">
          <TextField
            variant="outlined"
            fullWidth
            name="masterDrugCode"
            onChange={(e) => setMasterDrugCodes(e.target.value)}
            label="Enter master drug code (comma separated if multiple)"
          />
        </div>

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          loading={loading}
          disableSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
      </div>
    </>
  )
}

export default ReorderPointsSummary
