import React from 'react'

const PdfSvg = ({ width = 24, height = 30 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0H3C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3V27C0 27.7956 0.31607 28.5587 0.87868 29.1213C1.44129 29.6839 2.20435 30 3 30H21C21.7956 30 22.5587 29.6839 23.1213 29.1213C23.6839 28.5587 24 27.7956 24 27V9L15 0ZM8.247 21.285C7.7835 21.72 7.0995 21.915 6.303 21.915C6.14856 21.9167 5.99419 21.9077 5.841 21.888V24.027H4.5V18.123C5.10518 18.0327 5.71668 17.9916 6.3285 18C7.164 18 7.758 18.159 8.1585 18.4785C8.5395 18.7815 8.7975 19.278 8.7975 19.863C8.796 20.451 8.601 20.9475 8.247 21.285ZM13.9575 23.3175C13.3275 23.841 12.369 24.09 11.1975 24.09C10.4955 24.09 9.999 24.045 9.6615 24V18.1245C10.2669 18.0361 10.8782 17.9945 11.49 18C12.6255 18 13.3635 18.204 13.9395 18.639C14.562 19.101 14.952 19.8375 14.952 20.895C14.952 22.0395 14.5335 22.83 13.9575 23.3175ZM19.5 19.155H17.202V20.5215H19.35V21.6225H17.202V24.0285H15.843V18.045H19.5V19.155ZM15 10.5H13.5V3L21 10.5H15Z"
        fill="#2F80ED"
      />
    </svg>
  )
}

export default PdfSvg
