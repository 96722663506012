import React, { useEffect, useState } from 'react'
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'
import styles from './DraftPOView.module.scss'
import {
  createDraftPurchaseOrder,
  createPurchaseOrder,
  deleteDraftPurchaseOrderItem,
  draftPurchaseOrderItem,
} from '../../utils/api'
import { useNavigate } from 'react-router-dom'
import { Autocomplete, TextField } from '@mui/material'
import { vendorOptions } from '../../utils/constants'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import dayjs from 'dayjs' // Optional for easier date manipulation
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const DraftPOView = () => {
  const [loading, setLoading] = useState(false)
  const [rowModesModel, setRowModesModel] = useState({})
  const [draftItems, setDraftItems] = useState([])
  const [masterDrugCodes, setMasterDrugCodes] = useState('')
  const [selectedZohoIds, setSelectedZohoIds] = useState([]) // New state for selected Zoho IDs

  const navigate = useNavigate()

  const fetchDraftPurchaseOrderItems = async () => {
    try {
      let data = {
        data: {
          masterDrugCodes: masterDrugCodes
            .split(',')
            .map((drugCode) => parseInt(drugCode))
            .filter(Boolean),
          vendorZohoIds: selectedZohoIds.map((option) => option.id), // Send selected Zoho IDs as an array
        },
        params: {
          skip: 0,
          limit: 1500,
        },
      }
      const response = await draftPurchaseOrderItem()(data)
      if (response) {
        setDraftItems(response.data.draftPurchaseOrderItems)
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  const handleEditClick = (id) => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: 'edit' },
    }))
  }

  const handleSaveClick = (id) => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: 'view' },
    }))
  }

  const handleDeleteClick = async (id) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this item?',
    )
    if (!confirmed) return

    try {
      setLoading(true)
      const response = await deleteDraftPurchaseOrderItem()(id)
      if (response.data.success === 1) {
        fetchDraftPurchaseOrderItems() // Refresh the items after deletion
        console.log('Item deleted successfully:', id)
      }
    } catch (error) {
      console.error('Error deleting item:', error)
    } finally {
      setLoading(false)
    }
  }

  const processRowUpdate = async (updatedItem, oldItem) => {
    console.log('Processing row update:', updatedItem) // Debugging statement
    try {
      setLoading(true)
      const payload = {
        masterDrugCode: updatedItem.masterDrugCode,
        quantity: parseInt(updatedItem.quantity, 10),
        purchaseRate: parseFloat(updatedItem.purchaseRate),
        eta: updatedItem.eta,
        vendorZohoId: updatedItem.vendorZohoId,
      }

      const response = await createDraftPurchaseOrder()(payload)

      if (response.data.success === 1) {
        fetchDraftPurchaseOrderItems()
        setRowModesModel((prev) => ({
          ...prev,
          [updatedItem.id]: { mode: 'view' }, // Switch to view mode only after saving
        }))
      }
    } catch (error) {
      console.log('Error saving item:', error)
      return oldItem // Return the old item in case of an error, reverting the changes
    } finally {
      setLoading(false)
    }

    return updatedItem
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await createPurchaseOrder()()
      navigate('/procurement-dashboard/purchase-orders')
    } catch (error) {
      console.error('Error submitting draft P.O.:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDraftPurchaseOrderItems()
  }, [masterDrugCodes, selectedZohoIds])

  const columns = [
    {
      field: 'masterDrugCode',
      headerName: 'Drug Code',
      width: 100,
      type: 'number',
      valueFormatter: (row, value) => {
        return row ? row.toString() : ''
      },
    },
    {
      field: 'quantity',
      type: 'number',
      headerName: 'Quantity',
      width: 100,
      editable: true,
    },
    {
      field: 'purchaseRate',
      headerName: 'Purchase Rate',
      width: 120,
      type: 'number',
      editable: true,
    },
    {
      field: 'vendorZohoId',
      headerName: 'Vendor',
      width: 270,
      renderCell: (params) => {
        const vendor = vendorOptions.find((v) => v.id === params.value)
        return vendor ? vendor.name : 'Unknown'
      },
      // renderEditCell: (params) => (
      //   <FormControl fullWidth>
      //     <InputLabel>Vendor</InputLabel>
      //     <Select
      //       value={params.value}
      //       onChange={(event) => {
      //         params.api.setEditCellValue({
      //           id: params.id,
      //           field: 'vendorZohoId',
      //           value: event.target.value,
      //         })
      //       }}
      //       label="Vendor"
      //     >
      //       {vendorOptions.map((vendor) => (
      //         <MenuItem key={vendor.id} value={vendor.id}>
      //           {vendor.name}
      //         </MenuItem>
      //       ))}
      //     </Select>
      //   </FormControl>
      // ),
      // editable: true,
    },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 150,
      editable: true,
      renderEditCell: (params) => (
        <DatePicker
          value={dayjs(params.value)} // Use dayjs for easier date handling
          onChange={(newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: 'eta',
              value: newValue?.format('YYYY-MM-DD'),
            })
          }}
          renderInput={(inputProps) => <TextField {...inputProps} />}
          minDate={dayjs()} // Restrict date selection to today or future
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      type: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === 'edit'
        return isInEditMode
          ? [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={() => {
                  handleSaveClick(params.id)
                }}
                color="primary"
              />,
            ]
          : [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={() => handleEditClick(params.id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleDeleteClick(params.id)}
                color="inherit"
              />,
            ]
      },
    },
  ]

  const rows = draftItems.map((item) => ({
    id: item.id,
    masterDrugCode: item.masterDrugCode,
    quantity: item.quantity,
    purchaseRate: item.purchaseRate,
    vendorZohoId: item.vendorZohoId,
    eta: item.eta,
    createdAt: new Date(item.createdAt).toLocaleString(),
    updatedAt: new Date(item.updatedAt).toLocaleString(),
  }))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="home">
        <h1>
          <b> Requested P.O.'s</b>
        </h1>
        <div className="filter-header">
        <TextField
          variant="outlined"
          fullWidth
          label="Enter master drug code (Comma separated if multiple) "
          //placeholder="Comma separated if multiple"
          onChange={(e) => setMasterDrugCodes(e.target.value)}
        />
          <Autocomplete
            multiple
            options={vendorOptions}
            getOptionLabel={(option) => option.name}
            value={selectedZohoIds}
            onChange={(event, newValue) => setSelectedZohoIds(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Zoho Vendors"
                variant="outlined"
              />
            )}
            style={{ width: '90%', borderRadius: '15px' }}
          />
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          loading={loading}
          autoHeight
          disableSelectionOnClick
          editMode="row"
          slots={{ toolbar: GridToolbar }}
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
        <button
          onClick={handleSubmit}
          className={`${styles.submit_btn} prx-btn`}
        >
          Submit
        </button>
      </div>
    </LocalizationProvider>
  )
}

export default DraftPOView
