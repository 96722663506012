import React from "react";
import styles from "./cartDetails.module.scss";
import ItemDetails from "./ItemDetails";

const CartDetails = ({
  orderDetails,
  WareHouseOrderItems,
  handleCheckboxChange,
  checkedItems,
}) => {
  const data = orderDetails && orderDetails?.orderItems;
  const splitData = WareHouseOrderItems && WareHouseOrderItems;

  return (
    <>
      <h1>Order Details</h1>
      <div className={styles.details_container}>
        {data?.length > 0 &&
          data?.map((item, index) => (
            <div key={index}>
              <ItemDetails
                item={item}
                handleCheckboxChange={handleCheckboxChange}
                checkedItems={checkedItems}
              />
              {index !== data.length - 1 && (
                <div className={styles.horizontal_divider} />
              )}
            </div>
          ))}
        {splitData?.length > 0 &&
          splitData?.map((item, index) => (
            <div key={index}>
              <ItemDetails
                item={item}
                handleCheckboxChange={handleCheckboxChange}
                checkedItems={checkedItems}
              />
              {index !== splitData.length - 1 && (
                <div className={styles.horizontal_divider} />
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default CartDetails;
