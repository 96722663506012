import React, { useRef } from 'react'
import { Button } from '@mui/material'
import styles from './FileUpload.module.scss'

const FileUpload = ({ filePreviews, setFilePreviews }) => {
  const fileInputRef = useRef(null)

  const handleFilesChange = (e) => {
    const files = Array.from(e.target.files)
    const previews = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      type: file.type.startsWith('image') ? 'image' : 'video',
    }))

    setFilePreviews((prev) => [...prev, ...previews])
    e.target.value = ''
  }

  const handleRemoveFile = (fileIndex) => {
    setFilePreviews((prev) => prev.filter((_, index) => index !== fileIndex))

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <div className={styles.fileUploadContainer}>
      <Button
        variant="outlined"
        component="label"
        className={styles.uploadButton}
      >
        Choose Files
        <input
          type="file"
          accept="image/*, video/*, application/pdf"
          multiple
          onChange={handleFilesChange}
          ref={fileInputRef}
          hidden
        />
      </Button>
      <div className={styles.filePreviewContainer}>
        {filePreviews.length > 0 && (
          <div className={styles.filePreviewWrapper}>
            {filePreviews.map((filePreview, index) => (
              <div className={styles.filePreview} key={index}>
                {filePreview.type === 'image' ? (
                  <img src={filePreview.preview} alt="File preview" />
                ) : (
                  <video src={filePreview.preview} controls />
                )}
                <button
                  className={styles.removeButton}
                  onClick={() => handleRemoveFile(index)}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default FileUpload
